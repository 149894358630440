<template>
  <div class="changeGoods">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/stock' }">库存管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/stock' }">库存列表</el-breadcrumb-item>
      <el-breadcrumb-item>编辑</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="changeGoods-content">
      <el-form
        :model="goodsForm"
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- <el-form-item
            label="连锁商品ID"
            type="number"
            prop="addGoodsId"
            :rules="[{ required: true, message: '连锁商品ID不能为空' }]"
          >
            <el-input
              size="small"
              style="width: 240px"
              v-model.number="goodsForm.addGoodsId"
              autocomplete="off"
            ></el-input>
        </el-form-item> -->
        <el-form-item label="商品名称" prop="goodsName">
          <el-input
            v-model="goodsForm.goodsName"
            style="width: 240px"
            placeholder="请输入商品名称"
             :rules="[{ required: true, message: '商品名称不能为空' }]"
          ></el-input>
        </el-form-item>
        <el-form-item label="条形码编号" prop="goodsNum">
          <el-input
            v-model="goodsForm.goodsNum"
            style="width: 240px"
            placeholder="请输入条形码编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品价格" prop="goodsPrice">
          <el-input
            v-model="goodsForm.goodsPrice"
            style="width: 240px"
            placeholder="请输入商品价格"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="商品图片" class="shopInfo-item" prop="goodsImg">
          <div style="display: flex">
            <el-dialog :visible.sync="dialogVisible" :modal="false" width="40%">
              <img width="100%" :src="imgUrl + imageUrl" alt="" />
            </el-dialog>
            <el-upload
              :action="httpUrl + 'api/v1/images'"
              :http-request="uploadURL"
              name="image"
              class="avatar-uploader"
              :show-file-list="false"
            >
              <img v-if="imageUrl" :src="imgUrl + imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="imgTips">
              <div class="seeImg" @click="bigImg">浏览</div>
              <p style="margin-top: 10px">
                <span style="padding-right: 8px">*</span>图片大小：小于500KB
              </p>
              <p style="text-indent: 1em">图片尺寸：690px*316px</p>
            </div>
          </div>
        </el-form-item> -->
        <el-form-item style="display: flex; justify-content: center">
          <el-button
            type="primary"
            size="small"
            style="background: #cd0550; border: none"
            @click="changeGoods"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { imgUrl, httpUrl } from "@/utils/http.js"
import local from "@/utils/local.js"
import { getChainGoodChange_api } from "@/api/shop.js"
//阿里云直传
import {client} from "@/utils/alioss.js"

export default {
  data() {
    return {
      imgUrl: imgUrl,
      httpUrl: httpUrl,
      goodsForm: {
        goodsName: "",
        // addGoodsId: 0,
        goodsImg: "",
        goodsPrice:'',
        goodsNum:''
      },
      rules: {
        name: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
      },
      tk: "",
      imageUrl: "",
      dialogVisible:false
    }
  },
  methods: {
     uploadURL(file){
      let fileName="uploads/chain/image/"+`${Date.parse(new Date())}`+'.png'
      console.log(fileName)
      client().multipartUpload(fileName,file.file).then(res=>{
        this.imageUrl=res.name
      })
    },
    //预览图片
    bigImg() {
      this.dialogVisible = true
    },
    //文件上传成功
    handleAvatarSuccess(res, file) {
      this.imageUrl = res.data
    },
    //上传文件之前的钩子
    beforeAvatarUpload(file) {
      const isLt500k = file.size / 1024 < 500
      if (!isLt500k) {
        this.$message.error("上传头像图片大小不能超过 500kb!")
      }
      return isLt500k
    },
    //修改商品信息
    async changeGoods() {
      let res = await getChainGoodChange_api({
        id:this.goodsInfo.id,
        // chain_shop_good_id: this.goodsForm.addGoodsId,
        good_name: this.goodsForm.goodsName,
        good_img: this.imageUrl,
       good_code:this.goodsForm.goodsNum,
        good_price:this.goodsForm.goodsPrice
      })
      if (res.data.code == 0) {
        this.$message({
          message: "修改成功!",
          type: "success",
        })
        this.$router.push("/stock/goodsStock1")
      }
    },
  },
  created() {
    this.tk = local.get("tk")
     let goodsInfoA = local.get("goodsInfo")
    this.goodsInfo = JSON.parse(goodsInfoA)
    this.goodsForm.goodsName = this.goodsInfo.good_name
    // this.goodsForm.addGoodsId = this.goodsInfo.chain_shop_good_id
    this.goodsForm.goodsNum= this.goodsInfo.good_code
    this.goodsForm.goodsPrice = this.goodsInfo.good_price
    this.imageUrl = this.goodsInfo.good_img
  },
}
</script>

<style lang="less" scoped>
.changeGoods {
  width: 100%;
  height: 100%;
  background: #eee;
  padding: 30px;
  box-sizing: border-box;
  .changeGoods-content {
    padding: 20px;
    box-sizing: border-box;
    background: #fff;
    margin-top: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
  }
}
.avatar-uploader {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  width: 346px;
  height: 160px;
}
.el-upload {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 346px;
  height: 160px;
  line-height: 160px;
  text-align: center;
}
.avatar {
  width: 346px;
  height: 160px;
  display: block;
}
.imgTips {
  color: #cd0550;
  font-size: 12px;
  font-weight: bold;
  margin-left: 20px;
  p {
    line-height: 26px;
  }
}
.seeImg {
  cursor: pointer;
  width: 70px;
  height: 36px;
  background: #cd0550;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 36px;
}
</style>